import Image, { ImageProps } from "next/image"
import React, { useState } from "react"

const CustomImage = ({ src, alt, ...props }: ImageProps) => {
    const [isLoaded, setIsLoaded] = useState(false)
    const [imgSrc, setImgSrc] = useState(
        "/svg/v2/pass_ticket_fallback_image_light.png"
    )

    return (
        <Image
            key={imgSrc}
            src={isLoaded ? src : imgSrc}
            alt={alt || "fallback-image"}
            onLoad={() => setIsLoaded(true)}
            onError={() => {
                setImgSrc("/svg/v2/pass_ticket_fallback_image_light.png")
                setIsLoaded(false)
            }}
            {...props}
        />
    )
}

export default CustomImage
